<div>
    <h1>Absences Overview {{ currentYear }}</h1>
    <div *ngIf="schedulerRows">
        <app-scheduler
            *ngIf="schedulerRows.length > 0"
            [year]="currentYear"
            [schedulerRows]="schedulerRows"
        ></app-scheduler>
        <mat-card appearance="outlined" id="NoAbsences" *ngIf="schedulerRows.length === 0"
            >No absences found {{ currentYear }}</mat-card
        >
    </div>
</div>
