<div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h1>Self Service {{ currentYear }}</h1>
        <div *ngIf="schedulerRows && usersAvailableVacationDays">
            <button
                id="addVacationButton1"
                mat-stroked-button
                color="primary"
                style="margin-left: 20px"
                (click)="openCreateVacationDialog()"
            >
                Add Vacation
            </button>
        </div>
    </div>

    <div *ngIf="schedulerRows && usersAvailableVacationDays">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        You have {{ leftVacationDays }} vacation days left
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <br />

                <div
                    id="vacationDaysInformation"
                    *ngIf="
                        usersAvailableVacationDays.vacationDaysInformation
                            .length > 0
                    "
                    fxLayout="column"
                    class="cs-table"
                    style="line-height: 45px"
                >
                    <div class="cs-table-row">
                        <div fxFlex="0 0 300px">User</div>
                        <div fxFlex>Validity</div>
                        <div fxFlex>Vacation days</div>
                        <div
                            fxFlex="0 1 120px"
                            class="cgi-cell-right padding-left"
                        >
                            Vacation days left
                        </div>
                    </div>

                    <div
                        class="cs-table-row"
                        matRipple
                        [ngClass]="!isFirst ? 'nodivider' : ''"
                        *ngFor="
                            let item of usersAvailableVacationDays.vacationDaysInformation;
                            first as isFirst
                        "
                    >
                        <div fxLayout="row">
                            <div
                                fxFlex="0 0 300px"
                                title="{{ usersAvailableVacationDays.userId }}"
                            >
                                <ng-container *ngIf="isFirst">{{
                                    usersAvailableVacationDays.userName
                                }}</ng-container>
                            </div>
                            <div fxFlex>
                                {{ item.validFrom.format("DD.MM.YYYY") }} -
                                {{ item.validUntil.format("DD.MM.YYYY") }}
                            </div>
                            <div fxFlex>
                                {{ item.numberOfDays }}
                            </div>
                            <div
                                fxFlex="0 1 120px"
                                class="cgi-cell-right padding-left"
                            >
                                {{ item.numberOfDaysLeft }}
                            </div>
                        </div>
                    </div>
                    <div class="cs-table-row">
                        <div fxFlex="0 0 300px">Sum</div>
                        <div fxFlex></div>
                        <div fxFlex class="highlight">
                            {{
                                UsersAvailableVacationDaysModelUtil.sumDays(
                                    usersAvailableVacationDays
                                ).toFixed(1)
                            }}
                        </div>
                        <div fxFlex="0 1 120px" class="highlight">
                            {{
                                UsersAvailableVacationDaysModelUtil.sumDaysLeft(
                                    usersAvailableVacationDays
                                ).toFixed(1)
                            }}
                        </div>
                    </div>
                </div>

                <div
                    id="noVacationDays"
                    *ngIf="
                        usersAvailableVacationDays.vacationDaysInformation
                            .length === 0
                    "
                >
                    Vacation days were not created
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <br />

        <div>
            <app-scheduler
                [year]="currentYear"
                [schedulerRows]="schedulerRows"
                (onClick)="editVacation($event)"
            ></app-scheduler>
        </div>
        <br />
        <div fxLayout="row" fxLayoutAlign="space-between flex-start">
            <mat-card appearance="outlined" id="legend">
                <mat-card-content>
                    <div fxLayout="row" style="margin-bottom: 5px">
                        Vacation
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #ff8b15"
                        ></div>
                        <div>open</div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #26ab9c"
                        ></div>
                        <div>in progress</div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #2196f3"
                        ></div>
                        <div>applied</div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #ff1000"
                        ></div>
                        <div>declined</div>
                    </div>
                </mat-card-content>
            </mat-card>

            <div>
                <button
                    id="addVacationButton2"
                    mat-stroked-button
                    color="primary"
                    style="margin-left: 20px"
                    (click)="openCreateVacationDialog()"
                >
                    Add Vacation
                </button>
            </div>
        </div>
    </div>
</div>
