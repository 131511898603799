import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { DataDTO } from './home'
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ProjectRoleDTO } from "../projects/project-role"
import { UserProject } from "../projects/project";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  getWorkData(byYear: boolean): Observable<DataDTO>{
    const params = {
        byYear: byYear,
    };

    return this.http.get<DataDTO>(
        environment.backendUrl + `/api/home/users/work`,
        { params: params }
    );
  }

  getHoursPerProjectData(byYear: boolean): Observable<DataDTO>{
    const params = {
        byYear: byYear,
    };

    return this.http.get<DataDTO>(
        environment.backendUrl + `/api/home/users/hoursperproject`,
        { params: params }
    );
  }

  getProjectMemberData(): Observable<DataDTO>{
    return this.http.get<DataDTO>(environment.backendUrl + `/api/home/users/projectmember`);
  }

  getMostUsedRole(projectId: number): Observable<ProjectRoleDTO>{
    return this.http.get<ProjectRoleDTO>(environment.backendUrl + `/api/home/most/role/` + projectId);
  }

  getDefaultProject(): Observable<UserProject>{
    return this.http.get<UserProject>(environment.backendUrl + `/api/home/project/default`);
  }

  getCustomerProjectData(): Observable<DataDTO>{
    return this.http.get<DataDTO>(environment.backendUrl + '/api/home/projects/customers');
  }

  getProjectDistributionData(): Observable<DataDTO>{
    return this.http.get<DataDTO>(environment.backendUrl + '/api/home/project/distribution');
  }

  getProjectOverTimeData(timespan: number): Observable<DataDTO>{
    const params = {
        timespan: timespan,
    };

    return this.http.get<DataDTO>(
        environment.backendUrl + '/api/home/projects/overtime',
        { params: params }
    );
  }

  getProjectsRevenue(timespan: number): Observable<DataDTO>{
    const params = {
        timespan: timespan,
    };
    return this.http.get<DataDTO>(
        environment.backendUrl + `/api/home/revenue/projects`,
        { params: params }
    );
  }

  getRevenueByTimespan(timespan: number): Observable<DataDTO>{
    const params = {
        timespan: timespan,
    };
    return this.http.get<DataDTO>(
        environment.backendUrl + `/api/home/revenue/timespan`,
        { params: params }
    );
  }

  getAbsenceData(): Observable<DataDTO>{
    return this.http.get<DataDTO>(environment.backendUrl + `/api/home/vacation/absence`);
  }

  getAbsenceThisWeek():Observable<DataDTO[]>{
     return this.http.get<DataDTO[]>(environment.backendUrl + `/api/home/vacation/absence/week`);
  }

  getVacationDaysLeft(): Observable<DataDTO>{
     return this.http.get<DataDTO>(environment.backendUrl + `/api/home/vacation/left`);
  }

}
