import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { HomeService } from './home.service';
import { Observable, ReplaySubject } from "rxjs";
import { DataDTO, Dataset, Timespan, ChartInterface } from './home';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from "@angular/forms";
import * as moment from "moment";
import { Moment } from "moment";
import { ProjectService } from "../projects/project.service";
import { ProjectRoleService } from "../projects/project-role.service";
import { UserProject, ProjectMemberDTO } from "../projects/project";
import { TimesheetEntryCreateDTO } from "../timetracking/timesheet-entry";
import { TimetrackingService } from "../timetracking/timetracking.service";
import { ProjectRoleDTO } from "../projects/project-role";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { UserService } from "../users/user.service";
import { UserDetails, UserModel } from "../users/userDetails";
import { ReportingService } from "../reporting/reporting.service";
import { AbsenceService } from "../absence/absence.service";
import { AbsenceDTO } from "../absence/absence";
import { ReportingOverviewComponent, PdfExportModel } from "../reporting/reporting-overview/reporting-overview.component";
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { CreateVacationRequestDialogComponent, CreateVacationRequestDialogData } from '../absence/create-vacation-request-dialog/create-vacation-request-dialog.component';
import { CreateAbsenceDialogComponent, CreateAbsenceDialogComponentData } from '../absence/create-absence-dialog/create-absence-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { Mode } from "../mode";
import { AbsenceModel } from "../absence/absence";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.sass"],
})
export class HomeComponent implements OnInit {
    constructor(private msalService: MsalService, private router: Router,
    private homeService: HomeService, private projectService: ProjectService,
    private timetrackingService: TimetrackingService, private userService: UserService,
    private projectRoleService: ProjectRoleService,  private reportingOverview: ReportingOverviewComponent,
    private reportingService: ReportingService, private absenceService: AbsenceService, private dialog: MatDialog) {}

    me: ReplaySubject<UserDetails>;

    //Alle Daten nach Kategorie
    userDataOb: Array<Observable<DataDTO>> = [];
    projectDataOb: Array<Observable<DataDTO>> = [];
    revenueDataOb: Array<Observable<DataDTO>> = [];
    absenceDataOb: Array<Observable<DataDTO | DataDTO[]>> = [];

    //Alle Beschriftungen nach Kategorie
    userTexts: Array<string> = [];
    projectTexts: Array<string> = [];
    revenueTexts: Array<string> = [];

    //Vacation-Daten
    dataReady = false;

    absenceName: string;
    selectedAbsence: DataDTO;
    showOnlyPresent: boolean;

    //Auswahl welche Statistiken geladen werden; User, Projects, Revenue, Vacation
    loadAll = Array<boolean>(true, true, true, true);
    load = this.loadAll;

    loadFastEntry: boolean = true;

    //Auswahl der Kategorie
    justUser = Array<boolean>(true, false, false, false);
    justProjects = Array<boolean>(false, true, false, false);
    justRevenue = Array<boolean>(false, false, true, false);
    justVacation = Array<boolean>(false, false, false, true);

    //Parameter für Daten Fetching
    userDataByYear = false;
    projectDataByYear = Timespan.MONTH;
    revenueTimespan: Timespan = Timespan.MONTH;

    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    //Schnellerfassung Timesheet
    showCheckmark = false;
    hours = 0;
    minutes = 0;
    currentDate = new Date();

    favProject$: Observable<UserProject>;
    mostUsedRole$: Observable<ProjectRoleDTO>;

    favProject: UserProject;
    mostUsedRole: ProjectRoleDTO;
    task = "";

    allRoles: Observable<ProjectRoleDTO[]>;

    //Schnellerfassung Reporting
    showCheckmark2 = false;
    reportingPeriod: number;
    reportingProject: UserProject;
    user: UserDetails;
    language: string;

    reportingPeriods = [{value: 0, name: 'Current Month'}, {value: 1, name: 'Current Quarter'}, {value: 2, name: 'Current Half-Year'}, {value: 3, name: 'Current Year'},
    {value: 4, name: 'Last Month'}, {value: 5, name: 'Last Quarter'}, {value: 6, name: 'Last Half-Year'}, {value: 7, name: 'Last Year'}];
    myProjects: Observable<UserProject[]>;
    users: Observable<UserDetails[]>;
    reporter: Observable<UserDetails>;
    languages = [{value: 'de-DE', name: 'German (Germany)'}, {value: 'en-GB', name: 'English (UK)'},
    {value: 'en-US', name: 'English (US)'}];

    //Schnellerfassung Vacation & Absence
    fastAbsenceOb: Observable<AbsenceDTO>;

    ngOnInit(): void {
        if (this.msalService.instance.getAllAccounts().length > 0) {
            //this.router.navigateByUrl("/timesheet");
        }

        this.me = this.userService.me;

        this.reloadUsers();
        this.reloadProjects();
        this.reloadVacation();
        this.reloadRevenue();

        this.initFastEntryData();

    }

    /**
     Schnellerfassung
     */
     initFastEntryData(){
        this.initTimesheet();
        this.initReporting();
     }

    onStepChange(m: UserDetails,  projects?: UserProject[], event?: StepperSelectionEvent){
        this.initAbsence(m);
    }

    initTimesheet(){
        this.favProject$ = this.homeService.getDefaultProject();
                this.favProject$.subscribe(
                    project => {
                        this.favProject = project;
                        this.mostUsedRole$ = this.homeService.getMostUsedRole(project.id);
                        this.allRoles = this.projectRoleService.getCompensatedRolesForProject(this.favProject.id);
                    },
                    err => console.log(err),
                    () => this.mostUsedRole$.subscribe(
                        role => this.mostUsedRole = role
                    )
                )
    }

    initReporting(){
        this.myProjects = this.projectService.readMyProjects();
                this.users = this.userService.readUsers();
                this.reporter = this.userService.readMe();

                //Select active user as default for reporting
                this.reporter.subscribe(
                    r => this.user = r
                )
    }

    initAbsence(m: UserDetails){
        this.fastAbsenceOb = this.absenceService.readCurrentAbsenceByUser(m.principalId);
    }

    downloadReport(){
        this.reportingOverview.pdfExportModel = new PdfExportModel();
        switch(this.reportingPeriod) {
           case 0: {
              this.reportingOverview.setStartAndEndDateCurrentMonth(this.reportingOverview.pdfExportModel);
              break;
           }
           case 1: {
              this.reportingOverview.setStartAndEndDateCurrentQuarter(this.reportingOverview.pdfExportModel);
              break;
           }
           case 2: {
                this.reportingOverview.setStartAndEndDateCurrentHalfYear(this.reportingOverview.pdfExportModel);
                break;
           }
           case 3: {
                this.reportingOverview.setStartAndEndDateCurrentYear(this.reportingOverview.pdfExportModel);
                break;
           }
           case 4: {
                this.reportingOverview.setStartAndEndDateLastMonth(this.reportingOverview.pdfExportModel);
                break;
           }
           case 5: {
                this.reportingOverview.setStartAndEndDateLastQuater(this.reportingOverview.pdfExportModel);
                break;
           }
           case 6: {
                this.reportingOverview.setStartAndEndDateLastHalfYear(this.reportingOverview.pdfExportModel);
                break;
           }
           case 7: {
                 this.reportingOverview.setStartAndEndDateLastYear(this.reportingOverview.pdfExportModel);
                 break;
           }
        }
        this.reportingOverview.pdfExportModel.user = this.user;
        this.reportingOverview.pdfExportModel.locale = this.language;

        this.reportingOverview.downloadByUser();
        this.showCheckmark2 = true;
    }

    saveEntry() {
            const createDTO: TimesheetEntryCreateDTO = {
                projectId: this.favProject.id,
                roleId: this.mostUsedRole.id,
                year: this.currentDate.getFullYear(),
                month: this.currentDate.getMonth() + 1,
                day: this.currentDate.getDate(),
                duration: this.hours * 60 + this.minutes,
                description: this.task,
            };
            this.timetrackingService.saveTimesheetEntry(createDTO).subscribe(
                {
                    complete: () =>
                        {
                        this.showCheckmark = true;
                        this.reloadUsers();
                        this.reloadRevenue();
                        },
                }
            );
        }


    extendAbsence(m: UserDetails, extend: number){
        this.absenceService.extendAbsence(m.principalId, extend).subscribe(
            () => this.onStepChange(m)
        );
    }

    openAbsenceDialog(m: UserDetails){
        const createAbsenceDialogComponentData: CreateAbsenceDialogComponentData =
                        {
                            absence: new AbsenceModel(
                                                 null,
                                                 null,
                                                 null,
                                                 null,
                                                 null,
                                                 m.principalId,
                                                 m.displayName,
                                                 null
                                             ),
                            mode: Mode.CREATE,
                            hrRole: false
                        };

        this.dialog
                    .open(CreateAbsenceDialogComponent, {
                        data: createAbsenceDialogComponentData,
                        disableClose: true,
                    })
                    .afterClosed()
                    .subscribe(() => this.onStepChange(m));
            }


    openVacationRequestDialog(){
        const createVacationRequestDialogData: CreateVacationRequestDialogData =
                    {
                        hrRole: false,
                        mode: Mode.CREATE,
                    };

                this.dialog
                    .open(CreateVacationRequestDialogComponent, {
                        data: createVacationRequestDialogData,
                        disableClose: true,
                    })
                    .afterClosed()
                    .subscribe();
            }

    /**
     Datenfetching für Charts
     */
    reloadUsers(){

        this.userTexts[0] = "Working hours (" + this.monthNames[new Date().getMonth()] + ")";
        this.userTexts[1]= "Hours per Project (" + this.monthNames[new Date().getMonth()] + ")";
            if (this.userDataByYear){
                this.userTexts[0] = "Working hours (" + new Date().getFullYear() + ")";
                this.userTexts[1] = "Hours per Project (" + new Date().getFullYear() + ")";
            }

        this.userDataOb[0] = this.homeService.getWorkData(this.userDataByYear);
        this.userDataOb[1] = this.homeService.getHoursPerProjectData(this.userDataByYear);
        this.userDataOb[2] = this.homeService.getProjectMemberData();

    }

    reloadProjects(){

        this.projectTexts[0] = "Projects per month (" + new Date().getFullYear() + ")";
        if (this.projectDataByYear == 2){
            this.projectTexts[0] = "Projects per year";
        }

        this.projectDataOb[0] = this.homeService.getCustomerProjectData();
        this.projectDataOb[1] = this.homeService.getProjectDistributionData();
        this.projectDataOb[2] = this.homeService.getProjectOverTimeData(this.projectDataByYear);
    }

    reloadRevenue(){

            this.revenueTexts[0] = "Revenue per Project in € (" + this.monthNames[new Date().getMonth()] + ")";
            this.revenueTexts[1] = "Revenue in € (" + new Date().getFullYear() + ")";
            if (this.revenueTimespan == 1){
                this.revenueTexts[0] = "Revenue per Project in € (current Quarter)";
            }
            if (this.revenueTimespan == 2){
                this.revenueTexts[0] = "Revenue per Project in € (" + new Date().getFullYear() + ")";
                this.revenueTexts[1] = "Revenue in € (last 3 years)";
            }

            this.revenueDataOb[0] = this.homeService.getProjectsRevenue(this.revenueTimespan);
            this.revenueDataOb[1] = this.homeService.getRevenueByTimespan(this.revenueTimespan);

        }

    reloadVacation(){
        this.absenceDataOb[0] = this.homeService.getAbsenceData();
        this.absenceDataOb[1] = this.homeService.getAbsenceThisWeek();
        this.absenceDataOb[2] = this.homeService.getVacationDaysLeft();
    }

    /**
     Berechnungen auf Basis der Chart Daten
     */
    getOvertime(data: number): number{
        let goal = 120;
        let overtime = 0;

        if (this.userDataByYear){
            goal *= 12;
        }

       overtime = data - goal;
       return overtime
    }

    getTotalRevenueByProject(dataDto: DataDTO): DataDTO{
        let count = 0;
        let best = 0;
        let worst = 0;
        let current = dataDto.data[dataDto.data.length - 1];

        let result: DataDTO = {
            label: [],
            data: []
        };

        dataDto.data.forEach(
            (data, index) => {
                count += data;
                    if (data > best){
                        best = data
                        result.label[0] = dataDto.label[index]
                    }
                    if (data < worst || worst == 0){
                        worst = data
                        result.label[1] = dataDto.label[index]
                    }
                }
        )
        result.data[0] = +count.toFixed(2);
        result.data[1] = +best.toFixed(2);
        result.data[2] = +worst.toFixed(2);

        return result;
    }

    getTotalRevenueByTimespan(dataDto: DataDTO): DataDTO{
            let count = 0;
            let best = 0;
            let worst = 0;
            let current = dataDto.data[dataDto.data.length - 1];

            let result: DataDTO = {
                label: [],
                data: []
            };

            dataDto.data.forEach(
                (data, index) => {
                    count += data;
                        if (data > best){
                            best = data
                            result.label[0] = dataDto.label[index]
                        }
                        if (data < worst || worst == 0){
                            worst = data
                            result.label[1] = dataDto.label[index]
                        }
                    }
            )
            result.data[0] = +count.toFixed(2);
            result.data[1] = +best.toFixed(2);
            result.data[2] = +worst.toFixed(2);
            result.data[3] = +current.toFixed(2);

            result.label[2] = dataDto.label[dataDto.data.length - 1]; // Current Month

            return result;

        }

    changeAbsenceSelection(absences: DataDTO){
            this.selectedAbsence = {
                label: [],
                data: []
            };

            absences.label.forEach(
                (absence, index) =>
                {
                    if(absence.includes(this.absenceName))
                    {
                        this.selectedAbsence.label.push(absence);
                        this.selectedAbsence.data.push(absences.data[index]);
                    }
                }
            )
        }

    getCssClasses(value: number): string[]{
        let classes: string[] = [];
        classes.push('absence-base');

        switch (value) {
              case 1:
                 classes.push('absence-red'); //ILLNESS
                 if(this.showOnlyPresent){
                    classes.push('invisible')
                 }
                 break;
              case 2:
                 classes.push('absence-violet'); //BUSINESSTRIP
                 if(this.showOnlyPresent){
                    classes.push('invisible')
                 }
                 break;
              case 3:
                classes.push('absence-orange'); //VACATION
                if(this.showOnlyPresent){
                    classes.push('invisible')
                }
                break;
              default:
                classes.push('absence-green') //PRESENT
            }

        return classes;
    }

    /**
     Verhalten der Buttons unter Charts
     */
    openUsers(){
        this.router.navigateByUrl("/manage/users");
    }

    openProjects(){
        this.router.navigateByUrl("/manage/projects");
    }

    openCustomers(){
        this.router.navigateByUrl("/manage/customers")
    }

    openAbsences(){
        this.router.navigateByUrl("/manage/absences")
    }

    /**
     Bauen der Charts
     */
    buildSingleChart(data: DataDTO, title: string, type: string, unit?: string): ChartInterface {

        let dataPoints = new Array();

        for (let i = 0; i < data.label.length; i++) {
            dataPoints.push({label: data.label[i], y: data.data[i]})
        }

        let chart: ChartInterface = {
                    title: {
                        text: title,
                        fontFamily: "Roboto",
                        fontSize: 25
                    },
                    backgroundColor: "#595959",
                    theme: "dark2",
                    data: [{
                        type: type,
                        dataPoints: dataPoints,
                    }],
                    toolTip: {
                        enabled: true,
                        content: function(e) {
                            let content = "";
                            for (let i = 0; i < e.entries.length; i++) {
                                if(unit){
                                    content += e.entries[i].dataPoint.label + ": " + e.entries[i].dataPoint.y.toFixed(2) + ' ' + unit;
                                } else {
                                    content += e.entries[i].dataPoint.label + ": " + e.entries[i].dataPoint.y;
                                }
                                content += "<br/>";
                            }
                            return content;
                        }
                    }
                };
                return chart
    }

    buildMultipleChart(dataDTO: DataDTO[], title: string, type: string, legendTexts?: string[], unit?: string): any {

            let dataPoints = new Array();
            let dataset: Dataset[] = new Array() as Dataset[];

            for(let j = 0; j < dataDTO.length; j++){
                dataPoints = new Array();
                    for (let i = 0; i < dataDTO[j].label.length; i++) {
                        dataPoints.push({label: dataDTO[j].label[i], y: dataDTO[j].data[i]})
                    }
                dataset.push({showInLegend: true, legendText: legendTexts[j], type: type, dataPoints: dataPoints})
            }

            let chart = {
                        title: {
                            text: title,
                            fontFamily: "Roboto",
                            fontSize: 25
                        },
                        backgroundColor: "#595959",
                        theme: "dark2",
                        data: dataset,
                        toolTip: {
                            enabled: true,
                            content: function(e) {
                                let content = "";
                                for (let i = 0; i < e.entries.length; i++) {
                                    if(unit){
                                        content += e.entries[i].dataPoint.label + ": " + e.entries[i].dataPoint.y.toFixed(2) + ' ' + unit;
                                    } else {
                                        content += e.entries[i].dataPoint.label + ": " + e.entries[i].dataPoint.y;
                                    }
                                    content += "<br/>";
                                }
                                return content;
                            }
                        }
                    };
                    return chart
        }

    /**
     Hilfsfunktionen
     */
    splitArray(data: DataDTO, chunkSize: number): DataDTO[] {

        if (!data) {
            return [];
        }
        const result: DataDTO[] = new Array<DataDTO>();

        for (let i = 0; i < data.label.length; i += chunkSize) {
            let temp: DataDTO = {
                label: data.label.slice(i, i + chunkSize),
                data: data.data.slice(i, i + chunkSize)
            }

            result.push(temp)
        }
        return result;
    }

    getCurrentWeekNumber(): number {
        const currentDate = new Date()
        const dayNum = currentDate.getUTCDay() || 7;

        currentDate.setUTCDate(currentDate.getUTCDate() + 4 - dayNum);

        const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
        const weekNo = Math.ceil((((currentDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

        return weekNo;
    }

}

