import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AbsenceDTO, AbsenceRequest } from "./absence";
import { TimeRange } from "../common";
import * as moment from "moment";

@Injectable()
export class AbsenceService {
    constructor(private http: HttpClient) {}

    readAllAbsencesByUserAndTimerange(
        absenceFilterParams: AbsenceFilterParams
    ): Observable<AbsenceDTO[]> {
        const params = {
            userIds: absenceFilterParams.userIds.toString(),

            startDay: absenceFilterParams.startDay.toString(),
            startMonth: absenceFilterParams.startMonth.toString(),
            startYear: absenceFilterParams.startYear.toString(),

            endDay: absenceFilterParams.endDay.toString(),
            endMonth: absenceFilterParams.endMonth.toString(),
            endYear: absenceFilterParams.endYear.toString(),
        };

        return this.http.get<AbsenceDTO[]>(
            `${environment.backendUrl}/api/absences`,
            { params: params }
        );
    }

    readAbsencesWithoutVacationsByUserAndTimerange(
        absenceFilterParams: AbsenceFilterParams
    ): Observable<AbsenceDTO[]> {
        const params = {
            userIds: absenceFilterParams.userIds.toString(),

            startDay: absenceFilterParams.startDay.toString(),
            startMonth: absenceFilterParams.startMonth.toString(),
            startYear: absenceFilterParams.startYear.toString(),

            endDay: absenceFilterParams.endDay.toString(),
            endMonth: absenceFilterParams.endMonth.toString(),
            endYear: absenceFilterParams.endYear.toString(),
        };

        return this.http.get<AbsenceDTO[]>(
            `${environment.backendUrl}/api/absencesonly`,
            { params: params }
        );
    }

    deleteAbsence(absenceId: number): Observable<any> {
        return this.http.delete(
            `${environment.backendUrl}/api/absences/${absenceId}`,
            { responseType: "text" }
        );
    }

    saveAbsence(absence: AbsenceRequest): Observable<any> {
        return this.http.post(
            `${environment.backendUrl}/api/absences`,
            absence,
            {
                responseType: "text",
            }
        );
    }

    updateAbsence(absenceId: number, absence: AbsenceRequest): Observable<any> {
        return this.http.post(
            `${environment.backendUrl}/api/absences/${absenceId}`,
            absence,
            { responseType: "text" }
        );
    }

    readAbsenceById(absenceId: number) {
        return this.http.get<AbsenceDTO>(
            environment.backendUrl + `/api/absences/${absenceId}`
        );
    }

    readCurrentAbsenceByUser(userId: string): Observable<any>{
        return this.http.get<AbsenceDTO>(
            environment.backendUrl + `/api/absence/user/${userId}`
        );
    }

    extendAbsence(userId: string, extend: number): Observable<any> {
        const params = {
            extend: extend,
        };

        return this.http.post(
            `${environment.backendUrl}/api/absence/extend/${userId}`,
            {},
            { params: params }
        );
    }
}

export class AbsenceFilterParams {
    userIds?: String[];
    startDay: number;
    startMonth: number;
    startYear: number;
    endDay: number;
    endMonth: number;
    endYear: number;
}

// Vorbereitung fuer Filteroptionen
export class AbsenceFilter {
    selectedTimeRange: TimeRange = new TimeRange(
        moment().startOf("year"),
        moment().endOf("year")
    );
    selectedUser: String[] = [];
}
