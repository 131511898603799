<div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h1>Absence Management {{ currentYear }}</h1>
        <mat-slide-toggle
            id="correctionMode"
            class="example-margin"
            color="primary"
            [checked]="correction_mode"
            (toggleChange)="changeMode()"
        >
            correction mode
        </mat-slide-toggle>
    </div>

    <div *ngIf="schedulerRows">
        <div>
            <app-scheduler
                [year]="currentYear"
                [schedulerRows]="schedulerRows"
                (onClick)="editAbsence($event)"
            ></app-scheduler>
        </div>
        <br />

        <div id="legend" fxLayoutAlign="space-between flex-start">
            <mat-card appearance="outlined" fxLayoutAlign="space-between flex-start">
                <mat-card-content fxLayout="column">
                    <div fxLayout="row" style="margin-bottom: 5px">
                        Vacation Request
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #ff8b15"
                        ></div>
                        <div>open</div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #26ab9c"
                        ></div>
                        <div>in progress</div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #2196f3"
                        ></div>
                        <div>applied</div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #ff1000"
                        ></div>
                        <div>declined</div>
                    </div>
                </mat-card-content>

                <mat-card-content style="margin-left: 20px" fxLayout="column">
                    <div fxLayout="row" style="margin-bottom: 5px">
                        Absences
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <div
                            class="legend"
                            style="background-color: #696969"
                        ></div>
                        <div>other absences</div>
                    </div>
                </mat-card-content>
            </mat-card>
            <div>
                <button
                    id="addAbsence"
                    mat-stroked-button
                    color="primary"
                    style="margin-left: 20px"
                    (click)="openCreateAbscenceDialog()"
                >
                    Add Absence
                </button>
                <button
                    id="addVacation"
                    mat-stroked-button
                    color="primary"
                    style="margin-left: 20px"
                    (click)="openCreateVacationDialog()"
                >
                    Add Vacation
                </button>
            </div>
        </div>
    </div>
</div>
