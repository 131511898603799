import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";

@Component({
    selector: "app-scheduler",
    templateUrl: "./scheduler.component.html",
    styleUrls: ["./scheduler.component.scss"],
})
export class SchedulerComponent implements OnInit, AfterViewInit {
    @Input() year: number;
    @Input() schedulerRows: SchedulerRow[];
    currentMonth: number;
    daysOfYear: Moment[];
    months: string[];
    monthGrids: MonthGrid[];

    private monthElements: HTMLElement[] = [];

    private calenderElement: HTMLElement;
    private manualScroll: boolean;

    @Output()
    private onClick = new EventEmitter<SchedulerRowContent>();

    click(content: SchedulerRowContent) {
        this.onClick.emit(content);
    }

    constructor() {
        this.currentMonth = moment().month() + 1;
        this.months = moment.months();
    }

    ngOnInit(): void {
        this.fillMonthGrids();
        this.daysOfYear = this.getDaysOfYear();
    }

    ngAfterViewInit(): void {
        this.calenderElement = document.getElementById("calendar");

        this.months.forEach((value, index) =>
            this.monthElements.push(document.getElementById(`${index + 1}`))
        );
        this.jumpToElement();
    }

    private fillMonthGrids(): void {
        this.monthGrids = [];
        let gridPosition = 1;
        this.months.forEach((month) => {
            const daysInMonth = moment()
                .year(this.year)
                .month(month)
                .daysInMonth();
            const monthName = moment()
                .year(this.year)
                .month(month)
                .format("MMMM");
            this.monthGrids.push({
                monthName: monthName,
                gridColumnStart: gridPosition,
                gridColumnEnd: (gridPosition += daysInMonth),
            });
        });
    }

    private getDaysOfYear(): Moment[] {
        const days = [];
        let dayOfYear = moment().year(this.year).dayOfYear(1);
        while (dayOfYear.year() === this.year) {
            days.push(dayOfYear);
            dayOfYear = moment(dayOfYear).add(1, "day");
        }
        return days;
    }

    addMonth(addition: number): void {
        const currentMonth = this.currentMonth;
        const prevMonth = currentMonth + addition;

        if (prevMonth < 1) {
            this.currentMonth = 12;
        } else if (prevMonth > 12) {
            this.currentMonth = 1;
        } else {
            this.currentMonth = prevMonth;
        }
        this.jumpToElement();
    }

    jumpToElement(): void {
        this.manualScroll = false;
        this.calenderElement.scrollTo(0, 0);
        this.monthElements[this.currentMonth - 1].scrollIntoView();
    }

    changeMonthField(): void {
        if (this.manualScroll) {
            const offset = 100;
            for (let month = 1; month <= 12; month++) {
                const element =
                    this.monthElements[month - 1].getBoundingClientRect();
                if (element.left - offset >= 0 && element.right >= 0) {
                    this.currentMonth = month;
                    break;
                }
            }
        }
        this.manualScroll = true;
    }
}

interface MonthGrid {
    monthName: string;
    gridColumnStart: number;
    gridColumnEnd: number;
}

export interface SchedulerRow {
    name: string;
    information?: string;
    content: SchedulerRowContent[];
}

export interface SchedulerRowContent {
    start: Moment;
    end: Moment;

    id?: number;
    title?: string;
    color?: string;
    clickable?: boolean;
}
